<!-- AI绘画 以图生图 -->

<template>
	<div class="page_container">
		<div class="page_head">
			<div class="back" @click="goBack">
				<a-icon type="left-circle" :style="{ fontSize: '24px', color: '#c4d9f3' }" />
			</div>
			<div class="title">以图生图 · 一张图 一幅图</div>
		</div>
		<div class="page_main">
			<div class="canvas_box" :class="{ notright: loading || createData.length }">
				<div class="img_content">
					<div class="loading" v-if="loading">
						<img src="../assets/imgs/loading.png" alt="" />
						<p>正在创作中，请您耐心等待。。。</p>
					</div>
					<div v-else>
						<div class="img" v-if="createData.length" :style="{ transform: `scale(${scale})`, width: createImageSize.w + 'px', height: createImageSize.h + 'px' }">
							<img :src="createData[createActiveIndex].img" alt="" />
						</div>
						<div class="default" v-else>
							<a-icon type="picture" theme="filled" :style="{ fontSize: '40px' }" />
							<p>期待您的创作</p>
						</div>
					</div>
				</div>
				<div class="img_list" :class="{ show: createData.length || loading }">
					<div class="list" v-if="createData.length">
						<div class="item" :class="{ active: createActiveIndex == index }" v-for="(item, index) in createData" :key="'create_' + item.id" @click="changeCurrentImage(index)">
							<img :src="item.img" alt="" />
						</div>
					</div>
					<div class="list" v-if="loading">
						<div class="item" v-for="(item, index) in countActiveValue" :key="'loading_' + item">
							<a-spin />
						</div>
					</div>
				</div>
				<div class="img_tools" v-if="createData.length && !loading">
					<a-tooltip placement="right">
						<template slot="title">
							<span>收藏</span>
						</template>
						<div class="item" @click="collectHandle">
							<a-icon type="heart" theme="filled" :style="{ fontSize: '16px', color: createData[createActiveIndex].collect ? '#FF3434' : '' }" />
						</div>
					</a-tooltip>
					<a-tooltip placement="right">
						<template slot="title">
							<span>下载</span>
						</template>
						<div class="item" @click="downloadHandle">
							<a-icon type="download" />
						</div>
					</a-tooltip>
					<a-tooltip placement="right">
						<template slot="title">
							<span>放大</span>
						</template>
						<div class="item" @click="scaleHandle(0.1)">
							<a-icon type="zoom-in" />
						</div>
					</a-tooltip>
					<a-tooltip placement="right">
						<template slot="title">
							<span>缩小</span>
						</template>
						<div class="item" @click="scaleHandle(-0.1)">
							<a-icon type="zoom-out" />
						</div>
					</a-tooltip>
				</div>
			</div>
			<div class="config_box">
				<div class="mask_box" v-if="loading"></div>
				<div class="params_box">
					<div class="label_box">
						<div class="label">创作素材</div>
						<div class="upload">
							<div class="image" v-if="picture.image">
								<img :src="picture.image" alt="" />
								<div class="delete">
									<a-icon type="delete" :style="{ fontSize: '20px' }" @click="deleteUploadPicture" />
								</div>
							</div>
							<div class="add" v-else @click="picture.show = true">
								<a-icon type="plus" :style="{ fontSize: '26px', color: '#4c5b74' }" />
							</div>
						</div>
					</div>
					<div class="label_box">
						<div class="label">
							<span>画面描述</span>
							<div class="key_word">
								<span :class="{ active: keywordActiveIndex == index }" v-for="(item, index) in keywordData" :key="'keyword_' + index" @click="changeCurrentKeyword(index)">{{ item }}</span>
								<span @click="refreshKeyword">
									<a-icon type="sync" :spin="keywordUpdate" />
								</span>
							</div>
						</div>
						<div class="desc">
							<div class="desc_input">
								<a-textarea v-model="descValue" placeholder="请输入作品的描述词汇" :auto-size="{ minRows: 4 }" />
							</div>
							<div class="desc_content">
								<div class="item" :class="{ active: keywordTemplateActiveIndex == index }" v-for="(item, index) in keywordTemplateData" :key="'keywordTemplate_' + index" @click=";(keywordTemplateActiveIndex = index), (descValue = item)">{{ item }}</div>
							</div>
							<div class="desc_button" @click="refreshKeywordTemplate">
								<a-icon type="sync" :spin="keywordTemplateUpdate" />
								<span>换一换</span>
							</div>
						</div>
					</div>
					<div class="label_box">
						<div class="label">画布比例</div>
						<div class="list size">
							<div class="item" :class="{ active: sizeActive == item.id }" v-for="(item, index) in sizeData" :key="'size_' + item.id" @click="sizeActive = item.id">
								<div class="graph" :style="legendSize(item)"></div>
								<div class="text">{{ item.value }}</div>
							</div>
						</div>
					</div>
					<div class="label_box">
						<div class="label">定义风格</div>
						<div class="list style">
							<div class="item" :class="{ active: styleActive == item.id }" v-for="(item, index) in styleData" :key="'style_' + item.id" @click="styleActive = item.id">
								<div class="img">
									<img :src="item.img" alt="" />
									<p class="c-line-1">{{ item.value }}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="label_box">
						<div class="label">艺术家</div>
						<div class="list fillet">
							<div class="item" :class="{ active: artistActive == item.id }" v-for="(item, index) in artistData" :key="'artist_' + item.id" @click="artistActive = item.id">
								<div class="c-line-1">{{ item.value }}</div>
							</div>
						</div>
					</div>
					<div class="label_box">
						<div class="label">装饰词</div>
						<div class="list fillet">
							<div class="item" :class="{ active: item.checked }" v-for="(item, index) in bedeckData" :key="'bedeck_' + item.id" @click="item.checked = !item.checked">
								<div class="c-line-1">{{ item.value }}</div>
							</div>
						</div>
					</div>
					<div class="label_box">
						<div class="label">图片数量</div>
						<div class="list count">
							<div class="item" :class="{ active: countActive == item.id }" v-for="(item, index) in countData" :key="'count_' + item.id" @click="countActive = item.id">
								<div class="text">{{ item.value }}</div>
							</div>
						</div>
					</div>
					<div class="label_box">
						<div class="label">生成速度</div>
						<div class="list fillet speed">
							<div class="item" :class="{ active: speedActiveIndex == index }" v-for="(item, index) in speedData" :key="'speed_' + item.id" @click="speedActiveIndex = index">
								<div class="c-line-1">{{ item.value }}({{ item.usable }}积分)</div>
							</div>
						</div>
					</div>
				</div>
				<div class="button_box">
					<a-button type="primary" :loading="loading" class="button" @click="submitHandle">立即生成</a-button>
				</div>
			</div>
		</div>
		<a-modal v-model="picture.show" title="添加图片" width="70%" cancelText="取消" okText="确定" :maskClosable="false" @ok="addPictureOkHandle">
			<div class="picture_box">
				<div class="box_head">
					<a-radio-group v-model="picture.type" size="small">
						<a-radio-button :value="1">我的作品</a-radio-button>
						<a-radio-button :value="2">本地上传</a-radio-button>
					</a-radio-group>
				</div>
				<div class="box_body">
					<div class="image_list" v-if="picture.type == 1" @scroll="addPictureScroll">
						<a-row type="flex" align="middle">
							<a-col :span="24" :sm="12" :md="8" :lg="6" :xl="4" v-for="(item, index) in works.data" :key="'works_' + index">
								<div class="item">
									<div class="image" :class="{ active: picture.active == item.id }" @click="changeAddPicture(item.id, item.image)">
										<img :src="item.image" alt="" />
									</div>
								</div>
							</a-col>
						</a-row>
						<div class="status_text" v-if="works.data.length">
							<span class="more" v-if="works.status == 'loadmore'" @click="loadMorePicture">点击加载更多</span>
							<span v-if="works.status == 'loading'">加载中...</span>
							<span v-if="works.status == 'nomore'">没有更多了</span>
						</div>
					</div>
					<div class="image_list" v-if="picture.type == 2">
						<a-row type="flex" align="middle">
							<a-col :span="24" :sm="12" :md="8" :lg="6" :xl="4">
								<div class="item">
									<div class="upload">
										<a-upload name="file" list-type="picture-card" :show-upload-list="false" :action="upload.action" :headers="upload.headers" :accept="upload.accept" :data="upload.data" :before-upload="uploadPictureBefore" @change="uploadPictureChange">
											<a-icon :type="upload.loading ? 'loading' : 'plus'" :style="{ fontSize: '24px', color: '#8895a4' }" />
											<div class="text" v-if="!upload.loading">
												支持JPG、PNG格式 <br />
												20M内
											</div>
										</a-upload>
									</div>
								</div>
							</a-col>
							<a-col :span="24" :sm="12" :md="8" :lg="6" :xl="4" v-for="(item, index) in local.data" :key="'local_' + index">
								<div class="item">
									<div class="image" :class="{ active: picture.active == item.id }" @click="changeAddPicture(item.id, item.image)">
										<img :src="item.image" alt="" />
									</div>
								</div>
							</a-col>
						</a-row>
						<div class="status_text" v-if="local.data.length">
							<span class="more" v-if="local.status == 'loadmore'" @click="loadMorePicture">点击加载更多</span>
							<span v-if="local.status == 'loading'">加载中...</span>
							<span v-if="local.status == 'nomore'">没有更多了</span>
						</div>
					</div>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import { create, all } from "mathjs"
	const math = create(all, {
		number: "BigNumber",
		precision: 20
	})
	import { apiList } from "@/api/api"
	import { downloadPicture, sizePicture, Debounce } from "@/utils/tools"
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		data() {
			return {
				// 画布比例
				sizeData: [],
				sizeActive: 0,
				// 风格
				styleData: [{ id: 0, value: "不限定", img: require("@assets/imgs/style_default.png") }],
				styleActive: 0,
				// 艺术家
				artistData: [{ id: 0, value: "不限定" }],
				artistActive: 0,
				// 装饰词
				bedeckData: [],
				// 图片数量
				countData: [],
				countActive: 0,
				countActiveValue: 0,
				// 生成图片
				createData: [],
				createActiveIndex: 0,
				// 预览图缩放
				scale: 1,
				// 提交状态
				loading: false,
				// 描述词
				descValue: "",
				// 绘图任务ID
				drawingTaskId: "",
				// 定时器
				timerTask: null,
				// 关键词
				keywordData: [],
				keywordUpdate: false,
				keywordActiveIndex: 0,
				// 关键词模板
				keywordTemplateData: [],
				keywordTemplateUpdate: false,
				keywordTemplateActiveIndex: null,
				// 生成速度
				speedData: [],
				speedActiveIndex: 0,

				// 添加图片
				picture: {
					show: false,
					type: 1,
					active: null,
					image: ""
				},
				// 作品图片库
				works: {
					data: [],
					page: 1,
					pagesize: 20,
					status: "loadmore" // loadmore nomore loading
				},
				// 本地图片库
				local: {
					data: [],
					page: 1,
					pagesize: 20,
					status: "loadmore" // loadmore nomore loading
				},
				// 上传信息
				upload: {
					loading: false,
					action: apiList.common.upload.url,
					headers: {
						token: ""
					},
					accept: "image/png, image/jpeg",
					data: {
						is_user_image: 1
					}
				},
				prompt_id: this.$route.query.id
			}
		},
		watch: {
			"picture.show": {
				deep: true,
				handler: function (newValue, oldValue) {
					if (!newValue) {
						// this.picture.active = null
						// this.picture.image = ""
					}
				}
			},
			"picture.type": {
				deep: true,
				handler: function (newValue, oldValue) {
					this.picture.active = null
					this.picture.image = ""
				}
			}
		},
		computed: {
			...mapGetters("user", ["token"]),
			// 图例尺寸
			legendSize() {
				return item => {
					const size = sizePicture(true, item.w, item.h, 20)
					return {
						width: size.w + "px",
						height: size.h + "px"
					}
				}
			},
			// 预览图尺寸
			createImageSize() {
				return sizePicture(false, this.createData[this.createActiveIndex].w, this.createData[this.createActiveIndex].h, 350)
			}
		},
		created() {
			this.upload.headers.token = this.token

			this.getDrawingConfig()
			this.getKeyword()
			this.getDrawingRecord()
			this.getUploadMatter()
		},
		mounted() {},
		beforeDestroy() {
			clearInterval(this.timerTask)
		},
		methods: {
			// 加载更多图片 点击
			loadMorePicture() {
				if (this.picture.type == 1) {
					console.log("作品图片库")
					this.works.page++
					this.getDrawingRecord()
				} else {
					console.log("本地图片库")
					this.local.page++
					this.getUploadMatter()
				}
			},
			// 上传图片之后
			uploadPictureChange(info) {
				if (info.file.status === "uploading") {
					this.upload.loading = true
					return
				}
				if (info.file.response.code != 1) {
					this.upload.loading = false
					this.$message.error(info.file.response.msg)
					return
				}
				if (info.file.status === "done") {
					this.upload.loading = false
					const time = new Date().getTime()
					this.local.data.unshift({
						id: "local_" + time,
						image: info.file.response.data.fullurl
					})
				}
			},
			// 上传图片之前
			uploadPictureBefore(file) {
				const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
				if (!isJpgOrPng) {
					this.$message.error("仅支持JPG、PNG格式!")
				}
				const isLt2M = file.size / 1024 / 1024 < 20
				if (!isLt2M) {
					this.$message.error("图片大小超过20MB!")
				}
				return isJpgOrPng && isLt2M
			},
			// 修改当前选中添加的图片
			changeAddPicture(id, image) {
				this.picture.active = id
				this.picture.image = image
			},
			// 添加图片确认按钮
			addPictureOkHandle() {
				this.picture.show = false
			},
			// 滚动条监听
			addPictureScroll: Debounce(
				function (e) {
					let scrollTop = e.target.scrollTop
					let clientHeight = e.target.clientHeight
					let scrollHeight = e.target.scrollHeight
					if (scrollTop + clientHeight >= scrollHeight - 50) {
						if (this.picture.type == 1) {
							console.log("作品图片库")
							this.works.page++
							this.getDrawingRecord()
						} else {
							console.log("本地图片库")
							this.local.page++
							this.getUploadMatter()
						}
					}
				},
				20,
				false
			),
			// 获取素材数据
			getUploadMatter() {
				this.local.status = "loading"
				this.$http("drawing.matter", { page: this.local.page, pagesizze: this.local.pagesize }).then(res => {
					if (res.code === 1) {
						if (res.data && res.data.length) {
							const arr = res.data.map(({ id, fullimage }) => ({ id: "local_" + id, image: fullimage }))
							this.local.data = this.local.data.concat(arr)
							this.local.status = "loadmore"
						} else {
							this.local.page--
							this.local.status = "nomore"
						}
					}
				})
			},
			// 获取绘画记录
			getDrawingRecord() {
				this.works.status = "loading"
				this.$http("drawing.record", { page: this.works.page, pagesize: this.works.pagesize }).then(res => {
					if (res.code === 1) {
						if (res.data && res.data.length) {
							this.works.data = this.works.data.concat(res.data)
							this.works.status = "loadmore"
						} else {
							this.works.page--
							this.works.status = "nomore"
						}
					}
				})
			},
			// 删除上传图片
			deleteUploadPicture() {
				this.$confirm({
					title: "确定要删除图片吗？",
					content: "",
					okText: "确定",
					okType: "danger",
					cancelText: "取消",
					onOk: () => {
						this.picture.image = ""
						this.picture.active = null
					},
					onCancel: () => {}
				})
			},
			// 刷新关键词
			refreshKeyword() {
				this.keywordActiveIndex = 0
				this.keywordTemplateActiveIndex = null
				this.keywordUpdate = true
				this.getKeyword()
			},
			// 刷新关键词模板
			refreshKeywordTemplate() {
				this.keywordTemplateActiveIndex = null
				this.keywordTemplateUpdate = true
				this.getKeywordTemplate()
			},
			// 更改当前关键词
			changeCurrentKeyword(index) {
				this.keywordActiveIndex = index
				this.keywordTemplateActiveIndex = null
				this.getKeywordTemplate()
			},
			// 获取描述关键词
			getKeyword() {
				this.keywordData = []
				this.$http("drawing.keyword")
					.then(res => {
						if (res.code === 1) {
							this.keywordData = res.data
							this.getKeywordTemplate()
						}
						this.keywordUpdate = false
					})
					.catch(err => {
						this.keywordUpdate = false
					})
			},
			// 获取描述关键词模板
			getKeywordTemplate() {
				const kw = this.keywordData[this.keywordActiveIndex]
				this.keywordTemplateData = []
				this.$http("drawing.template", { key_word: kw })
					.then(res => {
						if (res.code === 1) {
							this.keywordTemplateData = res.data
						}
						this.keywordTemplateUpdate = false
					})
					.catch(err => {
						this.keywordTemplateUpdate = false
					})
			},
			// 获取绘画配置
			getDrawingConfig() {
				this.$http("drawing.config", { prompt_id: this.prompt_id }).then(res => {
					if (res.code === 1) {
						res.data.scale.length && ((this.sizeData = res.data.scale.map(({ id, name }) => ({ id, value: name, w: Number(name.split(":")[0]), h: Number(name.split(":")[1]) }))), (this.sizeActive = this.sizeData[0].id))
						res.data.style.length && (this.styleData = this.styleData.concat(res.data.style.map(({ id, image, name }) => ({ id, img: image, value: name }))))
						res.data.artists.length && (this.artistData = this.artistData.concat(res.data.artists.map(({ id, name }) => ({ id, value: name }))))
						res.data.magic_words.length && (this.bedeckData = res.data.magic_words.map(({ id, name }) => ({ id, value: name, checked: false })))
						// res.data.pic_size.length && ((this.countData = res.data.pic_size.map(({ id, name }) => ({ id, value: name }))), (this.countActive = this.countData[0].id))

						if (res.data.pic_size.length) {
							this.countData = res.data.pic_size.map(({ id, name }) => ({ id, value: name }))
							const arr = this.countData.filter(item => item.value == 4)
							arr && arr.length && (this.countActive = arr[0].id)
						}

						if (res.data.mode.length) {
							this.speedData = res.data.mode.map(({ name, type, usable }, index) => ({ id: index + 1, value: name, type, usable }))
						} else {
							this.$error({
								title: "系统提示",
								content: "请前往后台配置绘画信息"
							})
						}
					}
				})
			},
			// 提交
			submitHandle() {
				if (!this.sizeActive || !this.countActive) {
					Promise.reject("画布比例或图片数量ID错误 => 异常拦截")
					return
				}
				if (!this.picture.image) {
					this.$message.warning("请选择创作素材")
					return
				}
				if (!this.descValue) {
					this.$message.warning("请输入作品的描述词汇")
					return
				}
				if (!this.speedData[this.speedActiveIndex].type) {
					this.$message.warning("请选择绘画生成速度")
					return
				}

				const arr = this.bedeckData.filter(item => item.checked == true).map(item => item.id)
				this.countActiveValue = Number(this.countData.filter(item => item.id == this.countActive)[0].value)
				const obj = {
					image_url: this.picture.image,
					prompt_id: this.prompt_id,
					des: this.descValue,
					neg_prompt: "",
					style: this.styleActive,
					artists: this.artistActive,
					magic_words: arr.toString() || "",
					pic_size: this.countActive,
					scale: this.sizeActive,
					mode: this.speedData[this.speedActiveIndex].type
				}
				console.log(obj, "obj")

				this.loading = true
				this.$http("drawing.picture", obj).then(res => {
					if (res.code === 1) {
						this.drawingTaskId = res.data.task_id
						const timer = setTimeout(() => {
							this.getDrawingResult()
							clearTimeout(timer)
						}, 5000)
					} else {
						this.loading = false
						this.$message.error("生成图片失败！请刷新页面后重试。")
					}
				})
			},
			// 定时任务 (弃用)
			timerInterval() {
				this.timerTask = setInterval(() => {
					setTimeout(() => {
						if (this.loading) this.getDrawingResult()
						else clearInterval(this.timerTask)
					}, 0)
				}, 5000)
			},
			// 绘画结果
			getDrawingResult() {
				this.$http("drawing.result", { task_id: this.drawingTaskId }).then(res => {
					if (res.code === 1) {
						if (res.data) {
							if (res.data.length) {
								this.createData = this.createData.concat(res.data.map(({ id, image, width, height }) => ({ id, img: image, w: width, h: height, collect: false })))
							} else {
								this.$message.error("很抱歉，查询结果为空。")
							}
							this.loading = false
						}
					} else {
						this.loading = false
						this.$message.error("生成图片失败！请刷新页面后重试。")
					}

					if (this.loading) {
						const timer = setTimeout(() => {
							this.getDrawingResult()
							clearTimeout(timer)
						}, 5000)
					}
				})
			},
			// 改变当前预览图片
			changeCurrentImage(index) {
				this.createActiveIndex = index
				this.scale = 1
			},
			// 返回上一页
			goBack() {
				this.$router.back()
				clearInterval(this.timerTask)
			},
			// 下载
			downloadHandle() {
				this.createData[this.createActiveIndex].img && downloadPicture(this.createData[this.createActiveIndex].img)
			},
			// 缩放
			scaleHandle(value) {
				const number = +math.format(math.add(math.bignumber(this.scale), math.bignumber(value)))
				if (number > 2 || number < 0.5) return
				this.scale = number
			},
			// 收藏
			collectHandle() {
				this.$http("drawing.collect", { draw_images_id: this.createData[this.createActiveIndex].id, type: "vote" }, false).then(res => {
					if (res.code === 1) {
						this.createData[this.createActiveIndex].collect = !this.createData[this.createActiveIndex].collect
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep .ant-modal-content {
		.ant-modal-header {
			border: none;
		}
		.ant-modal-body {
			padding: 0 24px;
			height: 70vh;
			box-sizing: border-box;

			.picture_box {
				height: 100%;
				display: flex;
				flex-direction: column;
				.box_body {
					flex: 1;
					border: 1px dashed rgba(0, 0, 0, 0.45);
					border-radius: 10px;
					margin: 20px 0 10px 0;
					background: #f9f9fb;
					overflow: hidden;

					.image_list {
						height: 100%;
						overflow: hidden;
						padding: 12px;

						&:hover {
							overflow-y: scroll;
							overflow-x: hidden;
							padding-right: 0;
						}

						&::-webkit-scrollbar {
							width: 12px;
						}

						&::-webkit-scrollbar-thumb {
							border-radius: 12px;
							border: 4px solid rgba(0, 0, 0, 0);
							box-shadow: 4px 0 0 rgba(0, 0, 0, 0.2) inset;
						}

						&::-webkit-scrollbar-thumb:hover {
							box-shadow: 4px 0 0 #4a4a4a inset;
						}

						.item {
							position: relative;
							width: 100%;
							padding-top: 100%;
							overflow: hidden;

							.image {
								width: 100%;
								height: 100%;
								position: absolute;
								top: 0;
								left: 0;
								padding: 10px;
								cursor: pointer;
								border-radius: 10px;

								&.active {
									border-color: #424ef2;
									background: rgba($color: #424ef2, $alpha: 0.1);

									img {
										border-color: #424ef2;
									}
								}

								&:hover {
									border-color: #424ef2;
									background: rgba($color: #424ef2, $alpha: 0.1);

									img {
										border-color: #424ef2;
									}
								}

								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
									border-radius: 10px;
									border: 1.5px solid transparent;
								}
							}

							.upload {
								width: 100%;
								height: 100%;
								position: absolute;
								top: 0;
								left: 0;
								padding: 10px;
								cursor: pointer;

								.ant-upload-picture-card-wrapper {
									height: 100%;
								}
								.ant-upload.ant-upload-select-picture-card {
									width: 100%;
									height: 100%;
									border-radius: 10px;

									.text {
										font-size: 12px;
										color: #8895a4;
									}
								}
							}
						}

						.status_text {
							text-align: center;
							padding-top: 10px;

							.more {
								cursor: pointer;

								&:hover {
									color: #424ef2;
								}
							}
						}
					}

					.image_select {
						.ant-upload-select-picture-card i {
							font-size: 32px;
							color: #999;
						}
					}
				}
			}
		}
		.ant-modal-footer {
			border: none;
		}
	}

	.page_container {
		background: #222c3f;
		color: #c4d9f3;
		width: 100%;
		height: 100vh;
		overflow: hidden;
		display: flex;
		flex-direction: column;

		.page_head {
			height: 60px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid #000;

			.back {
				width: 60px;
				height: 60px;
				border-right: 1px solid #000;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				&:hover {
					background: #31415d;
				}
			}
			.title {
				font-size: 14px;
				margin-left: 10px;
			}
		}

		.page_main {
			flex: 1;
			display: flex;
			flex-wrap: nowrap;
			overflow: hidden;
			position: relative;

			.canvas_box {
				width: calc(100% - 500px);
				height: 100%;
				background: #222c3f;
				border-right: 1px solid #000;
				padding: 20px;
				display: flex;
				flex-wrap: nowrap;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;

				&.notright {
					padding-right: 0;
				}

				.img_tools {
					width: 42px;
					height: calc(100% - 40px);
					position: absolute;
					top: 40px;
					left: 30px;
					display: flex;
					flex-direction: column;
					align-items: center;

					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;

					.item {
						width: 32px;
						height: 32px;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 50%;
						background: #222c3f;
						color: #b7c9df;
						cursor: pointer;
						font-size: 18px;
						margin-bottom: 15px;

						&:last-child {
							margin-bottom: 0;
						}

						&:hover {
							background: #31415d;
						}
					}
				}

				.img_content {
					width: 100%;
					height: 100%;
					background-image: url("../assets/imgs/canvas_bg.png");
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 4px;
					overflow: hidden;

					.loading {
						img {
							width: 500px;
							height: auto;
						}

						p {
							text-align: center;
							font-size: 16px;
							transform: translateY(-80px);
						}
					}

					.default {
						text-align: center;

						p {
							font-size: 12px;
							margin-top: 8px;
						}
					}

					.img {
						width: 450px;
						height: 450px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}

				.img_list {
					width: 0;
					overflow: hidden;

					&.show {
						width: 160px;
					}

					&:hover {
						overflow-y: scroll;
						overflow-x: hidden;
					}

					&::-webkit-scrollbar {
						width: 12px;
					}

					&::-webkit-scrollbar-thumb {
						border-radius: 12px;
						border: 4px solid rgba(0, 0, 0, 0);
						box-shadow: 4px 0 0 #1a2232 inset;
					}

					&::-webkit-scrollbar-thumb:hover {
						box-shadow: 4px 0 0 #4a4a4a inset;
					}

					.list {
						margin-left: 16px;
						.item {
							width: 100px;
							height: 100px;
							border: 1.5px solid #4c5b74;
							overflow: hidden;
							border-radius: 4px;
							padding: 3px;
							margin-bottom: 20px;
							background: #141a26;
							cursor: pointer;
							display: flex;
							align-items: center;
							justify-content: center;

							&.active {
								border-color: #424ef2;
							}

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
								border-radius: 2px;
							}
						}
					}
				}
			}

			.config_box {
				width: 500px;
				height: 100%;
				background: #222c3f;
				position: absolute;
				top: 0;
				right: 0;
				z-index: 2;
				display: flex;
				flex-direction: column;

				.mask_box {
					width: 100%;
					height: 100%;
					background: rgba($color: #000000, $alpha: 0);
					position: absolute;
					top: 0;
					left: 0;
					z-index: 99;
				}

				.params_box {
					flex: 1;
					overflow: hidden;
					padding: 20px 12px;

					&:hover {
						overflow-y: scroll;
						overflow-x: hidden;
						padding-right: 0;
					}

					&::-webkit-scrollbar {
						width: 12px;
					}

					&::-webkit-scrollbar-thumb {
						border-radius: 12px;
						border: 4px solid rgba(0, 0, 0, 0);
						box-shadow: 4px 0 0 #1a2232 inset;
					}

					&::-webkit-scrollbar-thumb:hover {
						box-shadow: 4px 0 0 #4a4a4a inset;
					}

					.label_box {
						font-size: 12px;
						margin-bottom: 20px;

						.label {
							margin-bottom: 20px;
							display: flex;
							align-items: center;
							justify-content: space-between;

							.key_word {
								flex: 1;
								margin-left: 20px;
								text-align: right;

								span {
									margin-left: 15px;
									cursor: pointer;

									&.active {
										color: #3e97ff;
									}

									&:hover {
										color: #3e97ff;
									}
								}
							}
						}

						.desc {
							border: 1px solid #4c5b74;
							border-radius: 4px;
							overflow: hidden;
							.desc_input {
								textarea {
									background: transparent;

									border: none;
									color: #fff;
									font-weight: normal;
									font-size: 12px;
								}
							}

							.desc_content {
								border-top: 1px solid #4c5b74;
								border-bottom: 1px solid #4c5b74;

								.item {
									padding: 6px 10px;
									color: #8895a4;
									cursor: pointer;
									border: 1px solid transparent;

									&:hover {
										background: #38465d;
										color: #c4d9f3;
									}

									&.active {
										border-color: #424ef2;
										background: rgba($color: #424ef2, $alpha: 0.1);
										color: #c4d9f3;
									}
								}
							}

							.desc_button {
								height: 32px;
								display: flex;
								align-items: center;
								justify-content: center;
								cursor: pointer;

								&:hover {
									background: #38465d;
								}

								span {
									margin-left: 4px;
								}
							}
						}

						.list {
							display: flex;
							flex-wrap: wrap;
							.item {
								background: #1a2232;
								border-radius: 4px;
								border: 1px solid transparent;
								margin-right: 10px;
								margin-bottom: 10px;
								display: flex;
								flex-direction: column;
								align-items: center;
								justify-content: center;
								cursor: pointer;

								&:hover {
									border-color: #424ef2;
									background: rgba($color: #424ef2, $alpha: 0.1);
								}
								&.active {
									border-color: #424ef2;
									background: rgba($color: #424ef2, $alpha: 0.1);
								}
							}
						}

						.size {
							.item {
								width: calc((100% - 40px) / 5);
								height: 70px;

								&:nth-child(5n) {
									margin-right: 0;
								}

								.graph {
									width: 20px;
									height: 20px;
									border: 1px solid #c4d9f3;
									background: #222c3f;
									margin-bottom: 4px;
								}
							}
						}

						.style {
							.item {
								position: relative;
								width: calc((100% - 40px) / 5);
								height: 0;
								padding-top: calc((100% - 40px) / 5);
								border-color: #4c5b74;
								overflow: hidden;
								&:nth-child(5n) {
									margin-right: 0;
								}

								.img {
									position: absolute;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;
									padding: 2px;

									img {
										width: 100%;
										height: 100%;
										object-fit: cover;
										border-radius: 2px;
									}

									p {
										width: 100%;
										margin-bottom: 0;
										position: absolute;
										bottom: 0;
										left: 0;
										z-index: 1;
										background: rgba($color: #000000, $alpha: 0.5);
										backdrop-filter: blur(5px);
										text-align: center;
										font-size: 12px;
									}
								}
							}
						}

						.fillet {
							.item {
								width: calc((100% - 40px) / 5);
								height: 32px;
								border-radius: 32px;

								&:nth-child(5n) {
									margin-right: 0;
								}
							}
						}

						.count {
							.item {
								width: 32px;
								height: 32px;
							}
						}

						.speed {
							.item {
								width: calc((100% - 40px) / 3);
							}
						}
						.upload {
							width: 90px;
							height: 90px;
							border: 1px dashed #4c5b74;
							border-radius: 4px;
							overflow: hidden;
							cursor: pointer;

							.image {
								width: 100%;
								height: 100%;
								padding: 4px;
								position: relative;

								&:hover {
									.delete {
										display: flex;
									}
								}

								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}

								.delete {
									width: 100%;
									height: 100%;
									position: absolute;
									top: 0;
									left: 0;
									background: rgba($color: #000000, $alpha: 0.5);
									display: flex;
									align-items: center;
									justify-content: center;
									display: none;

									i {
										color: #fff;

										&:hover {
											color: #8895a4;
										}
									}
								}
							}
							.add {
								width: 100%;
								height: 100%;
								display: flex;
								align-items: center;
								justify-content: center;
							}
						}
					}
				}

				.button_box {
					height: 70px;
					display: flex;
					align-items: center;
					justify-content: center;

					.button {
						width: 300px;
						height: 46px;
						border-radius: 46px;
						border: none;
						background: linear-gradient(270deg, #ff4196 0%, #5c85ff 100%), linear-gradient(270deg, #ff4196 0%, #934bc4 49%, #2055f4 100%);
						position: relative;

						&:hover {
							background: linear-gradient(270deg, rgba($color: #ff4196, $alpha: 0.5) 0%, rgba($color: #5c85ff, $alpha: 0.5) 100%), linear-gradient(270deg, rgba($color: #ff4196, $alpha: 0.5) 0%, rgba($color: #934bc4, $alpha: 0.5) 49%, rgba($color: #2055f4, $alpha: 0.5) 100%);
						}
					}
				}
			}
		}
	}
</style>
